<template>
  <main class="projects" v-if="project.id">
    <div class="col justify-content-start align-items-baseline d-flex mb-2">
      <router-link v-if="$store.getters.permission.includes('project_edit')" type="button"
                   :to="`/projects/${$route.params.id}/edit/`"
                   class="btn btn-sm btn-dark">
        Редактировать
      </router-link>

      <router-link type="button"
                   v-if="($store.getters.user.is_superuser || ((project.project_managers||[]).includes($store.getters.user.id)) && ($store.getters.permission.includes('edit_search_result')))"
                   :to="{ name: 'correct_links', params: { id: $route.params.id }}"
                   class="mt-1 btn btn-sm  ms-2 bg-danger text-white">Редактировать выдачу
      </router-link>


      <router-link type="button" class="btn  btn-outline-primary ms-2 btn-sm d-inline-block"
                   :to="{ name: 'links_info', params: { id: $route.params.id }, query: {country_code: active_country?.code}}">
        Уникальные ссылки
      </router-link>


      <div class="ms-2 d-inline-block"
           v-if="!(['archive', 'audit', 'draft'].indexOf(project.project_type)+1) && ($store.getters.permission.includes('full_research'))">
        <button style="width: 100%" type="button" :disabled="!project.daily_state?.state"
                class="btn  btn-outline-primary btn-sm"
                @click="$modal('show', 'daily_parser')">Получить промежуточную выдачу
        </button>
        <div class="text-center text-secondary">
          <sup>{{ project.daily_state?.message }}<br/>
            <router-link v-if="project.daily_state && project.daily_state?.count"
                         :to="`/projects/${$route.params.id}/daily`">Промежуточные сборы ({{
                project.daily_state.count
              }}).
            </router-link>
          </sup>
        </div>
      </div>

      <div class="ms-2 d-inline-block"
           v-if="!(['archive', 'draft'].indexOf(project.project_type)+1)  && project.parser_state && ($store.getters.permission.includes('full_research'))">
        <div class="btn-group" role="group" aria-label="Basic example">
          <button type="button" :disabled="!project.parser_state.state" @click="$modal('show', 'restart_parser')"
                  class="btn btn-sm btn-outline-primary">Получить выдачу за сегодня
          </button>
          <button type="button" class="btn btn-sm btn-outline-primary" @click="$modal('show', 'restart_settings');"><i
              class="bi bi-three-dots-vertical"></i></button>
        </div>

        <div class="text-center text-secondary"><sup>{{ project.parser_state.message }}</sup></div>
      </div>

      <div class="ms-2 d-inline-block"
           v-if="project.project_managers?.includes($store.getters.user.id) || $store.getters.user.is_superuser">
        <button style="width: 100%" type="button" :disabled="!project.project_managers"
                class="btn  btn-outline-primary btn-sm"
                @click="addOdAccess">Получить доступ к папке проекта
        </button>

      </div>
    </div>

    <ProjectInfo :project.sync="project"></ProjectInfo>

    <div class="card-body">
      <div class="d-flex justify-content-start align-items-baseline">
        <h4 class="no-wrap me-2">Сводные данные </h4>
        <date-picker v-if="active_date"
                     style="width: 150px;"
                     locale="ru"
                     :text-input="true"
                     :clearable="false"
                     v-model="active_date"
                     format="dd.MM.yyyy"
                     model-type="yyyy-MM-dd"
                     auto-apply
                     :allowed-dates="allowedDates"
                     :enable-time-picker="false">
        </date-picker>
      </div>
      <detail_info
          v-bind:project="project"
          v-bind:date="active_date"
          v-bind:funcAll="getFull"
          v-bind:state="state"
          v-bind:full_info_show="full_info_show"
          v-bind:active_country="active_country"
          :allowedDates="allowedDates"
      >

      </detail_info>


      <div class="project-controls d-flex">
        <div class="controls d-flex">
          <div class="form-check form-switch">
            <input class="form-check-input" v-model="full_url" type="checkbox" role="switch" id="full_url">
            <label class="form-check-label" for="full_url">Полные ссылки</label>
          </div>
          <div class="form-check form-switch">
            <input class="form-check-input" v-model="group_color" type="checkbox" role="switch" id="group_color">
            <label class="form-check-label" for="group_color">В цветах групп</label>
          </div>
          <div class="form-check form-switch">
            <input class="form-check-input" v-model="show_non_check" type="checkbox" role="switch" id="show_unchecked">
            <label class="form-check-label" for="show_unchecked">Не проверено</label>
          </div>
          <div class="form-check form-switch" v-if="project.project_type === 'active'">
            <input class="form-check-input" v-model="info_by_date" type="checkbox" role="switch" id="info_by_date">
            <label class="form-check-label" for="info_by_date">Информация на выбранную дату</label>
          </div>
          <div class="form-check form-switch" v-if="project.project_type === 'active'">
            <input class="form-check-input" v-model="show_our" type="checkbox" role="switch" id="show_our">
            <label class="form-check-label" for="show_our">Отобразить "Наше"</label>
          </div>
        </div>
        <div class="controls d-flex">
          <button v-for="top in $STATES" type="button" @click="state=top.id"
                  :class="`btn btn-sm btn-outline-primary ${state===top.id?' active':''}`">
            {{ top.name }}
          </button>
          <date-picker v-if="active_date"
                       style="width: 150px;"
                       v-model="active_date"
                       locale="ru"
                       :text-input="true"
                       :clearable="false"
                       format="dd.MM.yyyy"
                       model-type="yyyy-MM-dd"
                       auto-apply
                       :allowed-dates="allowedDates"
                       :enable-time-picker="false">
          </date-picker>

          <CountryChoice
              v-bind:active_country.sync="project.active_country_code"
              v-bind:project_id="$route.params.id"
              v-bind:countries="project.countries"
              v-bind:changeCountry="changeCountry">
          </CountryChoice>
          <button type="button" v-for="engine in engines"
                  @click="engine.active = !engine.active"
                  :class="'btn btn-sm no-wrap ' + (engine.active?'btn-warning':'btn-outline-warning')">
            <i :class="engine.name"></i> {{ engine.name }}
          </button>

          <popper hover>

            <span slot="reference">
            <i class="bi bi-question-circle"></i>
          </span>
            <template #content>
                <span class="popper text-start">
            При нажатой клавише Shift можно подсветить  одинаковые ссылки
          </span>
            </template>
          </popper>

          <div class="diff d-flex" style=" margin-left: auto;" v-if="project.project_type === 'active'">
            <date-picker style="width: 150px"
                         v-model="diff_date.start"
                         locale="ru"
                         :clearable="false"
                         format="dd.MM.yyyy"
                         model-type="yyyy-MM-dd"
                         auto-apply
                         :allowed-dates="allowedDates"
                         :enable-time-picker="false"></date-picker>
            <i class="bi bi-arrow-right m-2"></i>
            <date-picker style="width: 150px" v-model="diff_date.end"
                         locale="ru"
                         :clearable="false"
                         format="dd.MM.yyyy"
                         model-type="yyyy-MM-dd"
                         auto-apply
                         :allowed-dates="allowedDates"
                         :enable-time-picker="false"></date-picker>
            <button type="button" class="btn btn-sm btn-outline-dark blue-bg ms-2"
                    @click="showDiff"><i class="bi bi-arrow-right-square"></i>
            </button>
          </div>

        </div>
        <div class="card-body border pt-0">
          <DiffDates v-if="diff_date.show"
                     v-bind:diff_date="diff_date"
                     v-bind:project_id="$route.params.id"
                     v-bind:start="diff_date.start"
                     v-bind:end="diff_date.end"
                     v-bind:engines="engines"
                     v-bind:active_country="active_country.code" v-bind:state="state">
          </DiffDates>

          <LinkDetail v-else-if="link"
                      v-bind:link_id="link"
                      v-bind:date="active_date"
                      v-bind:audit="true"
                      v-bind:country_code="active_country.code"
                      v-bind:project_id="$route.params.id" v-bind:keywords="keywords">
          </LinkDetail>
          <SearchResult v-else
                        :project="project"
                        :keywords="keywords"
                        :full_url="full_url"
                        :group_color="group_color"
                        :engines="engines"
                        :show_non_check="show_non_check"
                        :show_our="show_our"
                        :country_code="active_country.code"
                        :pager="{all: all_count, page: page, size: per_page, show: show_pager, upPage:upPage}"
          ></SearchResult>

        </div>

      </div>
      <modal modal_id="restart_parser" class="warning">
        <template v-slot:header>
          <h4>Перезапуск выдачи для проекта "{{ project.name }}"?</h4>
        </template>
        <template v-slot:body>
          <div class="d-flex">

            <h1 class="text-danger"><i class="bi bi-exclamation-lg"></i></h1>
            <p> Ранее собранная выдача за {{ $moment().format("DD-MM-YYYY") }} будет удалена и сбор выдачи будет
              запущен
              заново</p>
          </div>
        </template>
        <template v-slot:footer>
          <button type="button" class="btn btn-secondary" @click="$modal('hide', 'restart_parser');"
                  data-bs-dismiss="modal">Отмена
          </button>
          <button type="button" class="btn btn-danger" ref="restartButton" v-on:click="getSearchResult();">Подтвердить
          </button>
        </template>
      </modal>
      <modal modal_id="daily_parser">
        <template v-slot:header>
          <h4> Запустить сбор промежуточной выдачи для проекта
            "{{ project.name }}"?</h4>
        </template>
        <template v-slot:footer>
          <button type="button" class="btn btn-secondary" @click="$modal('hide', 'daily_parser');"
                  data-bs-dismiss="modal">Отмена
          </button>
          <button type="button" class="btn btn-danger" v-on:click="startDailySearch();">Подтвердить</button>
        </template>
      </modal>
      <modal modal_id="restart_settings">
        <template v-slot:header>
          <h4>Настройки перезапуска сбора "{{ project.name }}"?</h4>
        </template>

        <template v-slot:body>
          <RestartParser :project="project"></RestartParser>
        </template>
      </modal>


    </div>
  </main>
</template>

<script>
import {ProjectApi} from "@/api/project";
import LinkDetail from "@/views/projects/detail/includes/LinkDetail.vue";
import DiffDates from "@/views/projects/detail/includes/DiffDates.vue";
import RestartParser from "@/views/projects/detail/includes/RestartParser.vue";
import sermDetail from "@/views/projects/detail/includes/Serm.vue";
import SearchResult from "@/views/projects/detail/includes/SearchResult.vue";
import moment from "moment";
import ProjectInfo from "@/components/ProjectInfo.vue";
import detail_info from "@/views/projects/detail/includes/Serm.vue";


export default {
  name: 'ProjectDetail',
  components: {
    detail_info,
    LinkDetail,
    DiffDates,
    sermDetail,
    ProjectInfo,
    RestartParser,
    SearchResult
  },

  data() {
    return {
      project: {},
      info_by_date: false,
      show_our: false,
      old_active_date: null,
      old_active_country: null,
      active_date: null,
      old_state: null,
      state: null,
      full_url: false,
      group_color: false,
      full_info: false,
      show_non_check: false,
      full_info_show: false,
      diff_date: {start: '', end: '', start_obj: null, end_obj: null, show: false},
      page: 1,
      per_page: 10,
      all_count: 0,
      keywords: [],
      link: null,
      allowedDates: [],
      engine: this.$ENGINES.map(x => {
        return {name: x, active: true}
      }),
      scroll_link: null,
      show_pager: false
    }
  },
  computed: {
    active_country() {
      return this.project?.active_country_code
    },
    date_query() {
      if (this.active_date.includes("/")) return moment(this.active_date, 'DD/MM/YYYY').format("YYYY-MM-DD");
      return this.active_date;
    },
    engines() {
      return this.engine.filter(x => this.active_country ? this.active_country[x.name] : false)
    }
  },
  watch: {
    link(newV, oldV) {
      if (!newV && oldV && this.scroll_link) {
        setTimeout(() => {
          const element = document.getElementById("link-" + this.scroll_link);
          element.scrollIntoViewIfNeeded();
        }, 300)

      }
    },
    $route() {
      this.project = {}
      this.active_date = null
      this.page = 1
      this.getAvailDate()
      this.getProject()
    },
    project(val) {
      document.title = val.name || "Органическая выдача"
    },
    info_by_date() {
      this.getKeywords(this.project.id)
      this.getFullProject(this.project.id)
    },

    active_country: function (val) {
      if (this.old_active_country !== null && this.old_active_country?.id !== val?.id) this.getProject();
      this.old_active_country = val;
      this.page = 1
    },

    active_date: function (val) {
      if (this.old_active_date !== null) this.getProject();
      this.old_active_date = val;
      this.diff_date.end = val
      this.page = 1
      this.diff_date.start = moment(val).subtract(1, "days").format("YYYY-MM-DD");
    },

    state: function (val) {
      let data = {projects: {}}
      data.projects[this.project.id] = {state: val}
      this.$store.dispatch("setSettings", data)
      if (this.old_state !== null) {
        this.page = 1
        this.getKeywords(this.project.id);
      }
      this.old_state = val;
    },

  },
  mounted: function () {
    if (this.$route.query.link) this.linkInfo(this.$route.query.link, false);
    this.getProject()
    this.getAvailDate()
  },
  methods: {
    getAvailDate() {
      ProjectApi.get_avail_date_calendar(this.$route.params.id).then(response => this.allowedDates = response)
    },


    changeCountry: function (country) {
      if (this.active_country.code !== country.code) {
        this.project.active_country_code = country;
        this.full_info = false;
        this.full_info_show = false;
        this.page = 1
        this.getKeywords(this.project.id);
        this.getFullProject(this.project.id);
      }
    },
    closeLink: function () {
      history.pushState({}, null, `${this.$route.path}`);
      this.link = null;
    },
    getProject: function () {

      let $this = this;
      ProjectApi.get_simple(this.$route.params.id).then(response => {
        $this.project = response
        if (!$this.state) $this.state = $this.project.active_state;
        if (!$this.active_date) {
          $this.active_date = moment($this.project.last_parser, "DD/MM/YYYY").format("YYYY-MM-DD");
        }

        $this.getKeywords($this.project.id)
        $this.getFullProject()
      })
    },
    getFullProject: function () {
      let $this = this;
      ProjectApi.get($this.$route.params.id, $this.date_query, $this.active_country.code || 'RU', $this.info_by_date).then(response => {
        $this.project = {...$this.project, ...response};
        $this.project = {...$this.project, ...$this.project.stats};
      });
    },
    getKeywords: function (id) {
      let $this = this;
      ProjectApi.keywords($this.$route.params.id, $this.state, $this.date_query, $this.active_country.code || 'RU', $this.info_by_date,
          this.per_page, this.page).then(response => {
        if (this.page > 1) $this.keywords = $this.keywords.concat(response.results);
        else $this.keywords = response.results;
        $this.all_count = response.count;
        $this.show_pager = true;

      })
    },
    getFull: function () {
      let $this = this;
      if (!$this.full_info) {
        ProjectApi.get_full($this.$route.params.id, $this.date_query, $this.active_country.code || 'RU', $this.info_by_date).then(response => {
          $this.full_info = true;
          $this.full_info_show = true;
          $this.project = {
            ...$this.project,
            ...response
          };
        });
      } else $this.full_info_show = !$this.full_info_show;
    },
    linkInfo: function (id, state = true, link = null, event) {
      if (event) {
        event.preventDefault()
      }
      this.link = id;
      if (link) this.scroll_link = link.id
      if (link && !link.check) {
        link.check = true;
        this.project.no_check -= 1;
      }
      if (state) history.pushState({}, null, `${this.$route.path}?link=${id}`);
      // else {}

    },

    getSearchResult() {
      let $this = this;
      this.project.parser_state.state = false;
      this.$refs.restartButton.setAttribute('disabled', true)
      ProjectApi.start_search($this.$route.params.id).then((response) => {
        $this.$modal('hide', 'restart_parser');
        $this.$notify(response);
        $this.getFullProject()
      });
    },
    startDailySearch() {
      let $this = this;
      ProjectApi.start_daily_search($this.$route.params.id).then(response => {
        $this.$modal('hide', 'daily_parser');
        $this.$notify(response);
      });
    },

    upPage() {
      this.page += 1;
      this.show_pager = false;
      this.getKeywords()
    },
    showDiff() {
      this.diff_date.show = (!!this.diff_date.start && this.diff_date.end)
    },
    addOdAccess() {
      ProjectApi.add_od_access(this.$route.params.id).then(response => this.$notify(response))
    }

  }
}
</script>

